import {Shop} from "@one-commerce/sdk-shared";

import { ContextCallClass } from "../utils";

export class AddToCartContext extends ContextCallClass implements Shop.Context.AddToCart {
  productId: string;
  cartId: string;
  warehouses: Array<any>;
  warehouseId: string;
  meta: unknown;
  pluginProductLines: Array<any>;
  productLines: Array<any>;
  productAmount: number;
  closePlugin(): Promise<void> {
    return this.call('closePlugin')
  }
  closePluginAndAddToCart(): Promise<void> {
    return this.call('closePluginAndAddToCart')
  }
  closePluginAndRefreshCart(): Promise<void> {
    return this.call('closePluginAndRefreshCart')
  }
}

export class EditProductLinesOnCartContext extends ContextCallClass implements Shop.Context.EditProductLinesOnCart {
  productId: string;
  cartId: string | null;
  warehouses: Array<any>;
  warehouseId: string;
  meta: unknown;
  productLineId: string;
  productLines: Array<any>;
  pluginProductLines: Array<any>;
  productAmount: number;
  closePlugin(): Promise<void> {
    return this.call('closePlugin')
  }
  closePluginAndRefreshCart(): Promise<void> {
    return this.call('closePluginAndRefreshCart')
  }
}

export type ShopAvailableContext = AddToCartContext | EditProductLinesOnCartContext
