const dec2hex  = (dec: number): string => {
  return dec.toString(16).padStart(2, "0")
}

// generateId :: Integer -> String
export function generateCallId () {
  const arr = new Uint8Array((10 || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

export class BaseCallClass {
  serviceName = ''
  call: (fun: string, ...arg: any[]) => Promise<any>;
  constructor(call: (service: string, fun: string, ...arg: any) => Promise<any>) {
    this.call = (fun: string, ...arg: any) => {
      return call(this.serviceName, fun, ...arg)
    }
  }
  static update(target: Record<string, any>, payload: Record<string, any>) {
    Object.keys(payload).forEach((key: string) => {
      target[key] = payload[key]
    })
  }
  update(payload: any) {
    const attributes: any = payload[this.serviceName]
    if (attributes) {
      BaseCallClass.update(this, attributes)
    }
  }
}
export class ContextCallClass extends BaseCallClass {
  serviceName = 'context'
}
