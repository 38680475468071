import { Backoffice } from "@one-commerce/sdk-shared";
import { BaseCallClass } from "../utils";

export class BackofficeCatalog extends BaseCallClass implements Backoffice.Catalog {
  serviceName = 'catalog'

  fetchCategories(): Promise<Array<unknown>> {
    return this.call('fetchCategories')
  }
  public fetchProduct(id: string): Promise<unknown> {
    return this.call('fetchProduct', id)
  }
}

export class BackofficeUser extends BaseCallClass implements Backoffice.User {
  serviceName = 'user'
  email?: string;
  id!: string;
  name?: string;
  authorities!: Array<string>;
}

export class BackofficeTenant extends BaseCallClass implements Backoffice.Tenant {
  serviceName = 'tenant'
  tenantKey?: string;
  language?: string;
  currency?: { code: string; iso: string; symbol: string; };
}

export class BackofficeLayout extends BaseCallClass implements Backoffice.Layout {
  serviceName = 'layout'
  isAsideOpened!: boolean;
}

export class BackofficeAuthorization extends BaseCallClass implements Backoffice.Authorization {
  serviceName = 'authorization'

  getAuthToken(): Promise<string> {
    return this.call('getAuthToken')
  }
}

export class BackofficeCustomer extends BaseCallClass implements Backoffice.Customer {
  serviceName = 'customer'
  id?: string;
  name?: string;
}

export class BackofficePlugin extends BaseCallClass implements Backoffice.Plugin {
  serviceName = 'plugin'
  id: string;
  srcUrl?: string;
  name?: string;
}

export class BackofficeOrderpath extends BaseCallClass implements Backoffice.Orderpath {
  serviceName = 'orderpath'
  fetchCart(id: string): Promise<unknown> {
    return this.call('fetchCart', id)
  }
  fetchOffer(id: string): Promise<unknown> {
    return this.call('fetchOffer', id)
  }
}
