import { Shop } from "@one-commerce/sdk-shared";

import { BaseCallClass } from "../utils";

export class ShopCatalog extends BaseCallClass implements Shop.Catalog {
  serviceName = 'catalog'
  categories!: Array<any>;
  selectedCategoryId!: string | null;

  fetchProduct(productId: string): Promise<any> {
    return this.call('fetchProduct', productId)
  }

  fetchProducts(page: unknown): Promise<unknown> {
    return this.call('fetchProducts', page)
  }

  fetchStocksForProductsAndWarehouses(products: Array<string>, warehouses: Array<string>): Promise<unknown> {
    return this.call('fetchStocksForProductsAndWarehouses', products, warehouses)
  }

  getProductPrice(productId: string, isGross: boolean): Promise<unknown> {
    return this.call('getProductPrice', productId, isGross)
  }

  fetchPricingForProducts(products: Array<{
    productId: string
    quantity?: number
  }>): Promise<unknown> {
    return this.call('fetchPricingForProducts', products)
  }

  fetchWarehouses(): Promise<unknown> {
    return this.call('fetchWarehouses')
  }

  getCategoryById(categoryId: string): Promise<any> {
    return this.call('getCategoryById', categoryId)
  }
}
export class ShopWishlist extends BaseCallClass implements Shop.Wishlist {
  serviceName = 'wishlist'

  addProductsToWishlist(wishlistId: string, newProduct: any): Promise<void> {
    return this.call('addProductsToWishlist', wishlistId, newProduct)
  }

  getWishlists(): Promise<any> {
    return this.call('getWishlists')
  }

  createWishlist(name: string): Promise<any> {
    return this.call('createWishlist', name)
  }
}
export class ShopAccount extends BaseCallClass implements Shop.Account {
  serviceName = 'account'
  client!: {
    id: string;
    name: string;
    externalId?: string;
    tin?: string;
    phone?: string;
    segmentId?: string;
    segments: {
      additionalSegments?: Array<{
        id: string;
        name: string;
        externalId?: string;
        parentSegmentId?: string;
      }>;
      mainSegment: {
        id: string;
        name: string;
        externalId?: string;
        parentSegmentId?: string;
      };
    };
    warehouseId?: string;
    termPayment?: {
      id: string;
      label?: string;
      paymentDaysPeriod?: number;
    };
    isTaxPayer?: boolean;
    billingAddress?: {
      city: string;
      country: string;
      id: string;
      isDefault: boolean;
      name: string;
      phone: string;
      street: string;
      type: string;
      zipCode: string
    }
  };
  isAuthenticated!: boolean;
  user!: {
    id?: string,
    clientId?: string,
    email?: string;
    name?: string;
    phone?: string;
    rol?: {
      name: string;
      id: string;
    }
    /**
     * @deprecated Use non-nested function instead
     */
    hasSegment(name: string): Promise<boolean>
  };

  hasSegment(name: string): Promise<boolean> {
    return this.call('hasSegment', name)
  }

  manager!: {
    additionalEmail?: string;
    email: string; id: string;
    landlinePhone?: string;
    mobilePhone?: string;
    name: string;
    photo?: string
  };

  logout(): void {
    // NotImplementedYet
  }
}

export class ShopCart extends BaseCallClass implements Shop.Cart {
  serviceName = 'cart'
  carts!: Array<any>;
  currentCart!: any;
  totalCartPrice!: number;

  addToCart(productId: string, amount: number, where: string, cartId?: string): Promise<void> {
    return this.call('addToCart', productId, amount, where, cartId)
  }
}

export class ShopAuthorization extends BaseCallClass implements Shop.Authorization {
  serviceName = 'authorization'

  getAuthToken(): Promise<string> {
    return this.call('getAuthToken')
  }
}

export class ShopUtils extends BaseCallClass implements Shop.Utils {
  serviceName = 'utils'
  currency!: any;
  isAnonymousAndPurchaseDisabled!: boolean;
  isB2B!: boolean;
  isB2BAndPlatformIsNotOpened!: boolean;
  isIndexPage!: boolean;
  isPurchaseEnabledOrAuthenticated!: boolean;
  notify(options: NotificationOptions | string): Promise<void> {
    return this.call('notify', options)
  }

  constructor(call: (service: string, fun: string, ...arg: any) => Promise<any>) {
    super(call)
    this.routes = {
      getCategoryPath: this.getCategoryPath,
      getManufacturerSearchUrl: this.getManufacturerSearchUrl,
      getProductUrl: this.getProductUrl,
      isExternalUrl: this.isExternalUrl
    }
  }

  getCategoryPath(id?: string): Promise<string | null> {
    return this.call('getCategoryPath', id)
  }

  getManufacturerSearchUrl(manufacturer: string): Promise<string> {
    return this.call('getManufacturerSearchUrl', manufacturer)
  }

  getProductUrl(product: any): Promise<string | null> {
    return this.call('getProductUrl', product)
  }

  isExternalUrl(url: string): Promise<boolean> {
    return this.call('isExternalUrl', url)
  }

  routes: {
    /**
     * @deprecated Use non-nested function instead
     */
    getCategoryPath(id?: string): (Promise<string | null> | string | null);
    /**
     * @deprecated Use non-nested function instead
     */
    getManufacturerSearchUrl(manufacturer: string): (Promise<string> | string);
    /**
     * @deprecated Use non-nested function instead
     */
    getProductUrl(product: any): (Promise<string | null> | string | null);
    /**
     * @deprecated Use non-nested function instead
     */
    isExternalUrl(url: string): (Promise<boolean> | boolean)
  };
}

export class ShopConfig extends BaseCallClass implements Shop.Config {
  serviceName = 'config'
  accessMode!: string;
  isB2CRegistrationEnabled!: boolean;
  isCatalogOpened!: boolean;
  isGrossSelected!: boolean;
  isPlatformClosed!: boolean;
  isPlatformOpened!: boolean;
  isPurchaseEnabled!: boolean;
  isRegistrationEnabled!: boolean;
  currency: any;
}

export class ShopTenant extends BaseCallClass implements Shop.Tenant {
  serviceName = 'tenant'
  tenantKey: string | null;
  language: string;
}

export class ShopPlugin extends BaseCallClass implements Shop.Plugin {
  serviceName = 'plugin'
  id: string;
  srcUrl?: string;
  name?: string;
}
