class Events {
  _callbacks: Record<string, any>
  constructor () {
    this._callbacks = {}
  }

  on<T>(key: string, callback: (arg: T) => void) {
    // create an empty array for the event key
    if (this._callbacks[key] === undefined) { this._callbacks[key] = [] }
    // save the callback in the array for the event key
    this._callbacks[key].push(callback)
  }

  off<T>(key: string, callback: (arg: T) => void) {
    // if the key exists
    if (this._callbacks[key] !== undefined) {
      // iterate through the callbacks for the event key
      for (let i=0; i<this._callbacks[key].length; i++) {
        // if the callback matches, remove it
        if (this._callbacks[key][i] === callback) {
          this._callbacks[key].splice(i, 1)
        }
      }
    }
  }

  emit(key: string, ...params: Array<any>) {
    // if the key exists
    if (this._callbacks[key] !== undefined) {
      // iterate through the callbacks for the event key
      for (let i=0; i<this._callbacks[key].length; i++) {
        // trigger the callbacks with all provided params
        this._callbacks[key][i](...params)
      }
    }
  }
}

export default Events
