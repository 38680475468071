import {
  Backoffice,
  Message,
  Route,
  Shop
} from "@one-commerce/sdk-shared";
import Channel from "iframe-channel"

import {
  AddToCartContext as BackofficeAddToCartContext,
  BackofficeAvailableContext,
  EditProductLinesOnCartContext as BackofficeEditProductLinesOnCartContext,
} from "./backoffice/context";
import {
  BackofficeAuthorization,
  BackofficeCatalog,
  BackofficeCustomer,
  BackofficeLayout,
  BackofficeOrderpath,
  BackofficePlugin,
  BackofficeTenant,
  BackofficeUser,
} from "./backoffice/services";
import Events from "./events";
import {
  AddToCartContext as ShopAddToCartContext,
  ShopAvailableContext,
  EditProductLinesOnCartContext as ShopEditProductLinesOnCartContext
} from "./shop/context";
import {
  ShopAccount,
  ShopAuthorization,
  ShopCart,
  ShopCatalog,
  ShopConfig,
  ShopPlugin,
  ShopTenant,
  ShopUtils,
  ShopWishlist,
} from "./shop/services";
import { BaseCallClass, generateCallId } from "./utils";


enum SdkInternalEvents {
  INIT='INIT',
  UPDATE='UPDATE'
}
export enum SdkEvents {
  INIT='INIT',
  UPDATE='UPDATE',
}
export interface SdkOptions<S> {
  debug?: boolean
  autoheight?: boolean
  origin: string,
  context?: Class<S>;
}

interface NamedNavigationPayload {
  name: string
  params: any
}

interface PathNavigationPayload {
  path: string
  params: any
}

export type Class<T = any> = new (...args: any[]) => T;

abstract class Sdk<T, S = BackofficeAvailableContext | ShopAvailableContext> extends Events {
  private channel!: Channel;
  private static debug = false
  private autoheight = true
  private heightMutationObserver = null
  origin: string;
  isInitialized = false
  instances!: T;
  contextClass?: Class<S>;

  private static log(...data: any[]) {
    Sdk.debug && console.log('[SDK Core] ', ...data)
  }

  private static warn(...data: any[]) {
    console.warn('[SDK Core] ', ...data)
  }

  private initializeServiceGetters(): void {
    if (this.instances) {
      Object.keys(this.instances).forEach((serviceName: string) => {
        Object.defineProperty(this, serviceName, {
          get: () => {
            return this.isInitialized ? this.instances![serviceName as keyof T] : null
          }
        })
      })
    }
  }

  public initializeContextClass(): S {
    if (this.contextClass) {
      return new this.contextClass(this.callFunction.bind(this))
    }
    return {} as S
  }

  constructor(options: SdkOptions<S>) {
    super()
    this.initializeServiceGetters()
    this.origin = options.origin
    this.channel = new Channel({
      targetOrigin: this.origin,
      target: window.parent,
    })
    this.channel.subscribe(Message.INIT, (payload: any) => {
      Sdk.log('Handling INIT message')
      Object.entries(this.instances!).forEach(([key, value]: [key: string, value: BaseCallClass | Record<string, any>]) => {
        if (value instanceof BaseCallClass) {
          value.update(payload)
        } else {
          const attributesForKey = payload[key]
          if (typeof attributesForKey !== 'undefined') {
            BaseCallClass.update(value as Record<string, any>, payload[key])
          } else {
            Sdk.warn(`Missing attributes for ${key} in `, payload)
          }
        }
      })
      Sdk.log('Emitting INIT with payload ', this.instances)
      this.isInitialized = true
      this.emit(SdkInternalEvents.INIT, this.instances)
    })
    this.channel.subscribe(Message.UPDATE, (payload: any) => {
      Sdk.log('Emitting UPDATE with payload ', payload)
      this.emit(SdkInternalEvents.UPDATE, payload)
    })
    this.channel.subscribe(Message.FRAME_HEIGHT_REQUEST, (payload: any) => {
      if (this.autoheight) {
        Sdk.log('Request for iframe height')
        this.sendFrameHeight()
      }
    })
    this.channel.connect().then(() => {
      Sdk.log('Connected with ONe SDK')
      if(this.autoheight) {
        this.initializeHeightObserver()
      }
    })
    this.setupOptions(options)
    Sdk.log('Initialized.')
  }

  private sendFrameHeight() {
    this.channel.postMessage(
      Message.FRAME_HEIGHT_CHANGE,
      document.body.scrollHeight
    )
  }

  private initializeHeightObserver() {
    if ("MutationObserver" in window) {
      const onMutationObserved = () => {
        this.sendFrameHeight()
      }
      const target = document.querySelector('body'),
        config = {
          attributes: true,
          attributeOldValue: false,
          characterData: true,
          characterDataOldValue: false,
          childList: true,
          subtree: true
        }

      this.heightMutationObserver = new MutationObserver(onMutationObserved)

      Sdk.log('Create body MutationObserver')
      this.heightMutationObserver.observe(target, config)
    } else {
      Sdk.warn('MutationObserver not available. Auto-height functionality disabled.')
    }
  }

  private setupOptions(options?: SdkOptions<S>) {
    if (options) {
      if (typeof options.debug !== 'undefined') {
        Sdk.debug = options.debug
      }
      if (typeof options.autoheight !== 'undefined') {
        this.autoheight = options.autoheight
      }
      this.contextClass = options.context
    }
  }
  protected callFunction(service: string, fun: string, ...payload: any) {
    const callId: string = generateCallId()
    if (this.isInitialized) {
      Sdk.log(`Call ID ${callId} for ${service}:${fun}`, payload)
      this.channel.postMessage(Message.METHOD_CALL, {
        callId,
        service,
        method: fun,
        payload: payload,
      })
      return new Promise((resolve, reject) => {
        const onChannelMessage = (response: any) => {
          if (response.callId === callId) {
            this.channel.unsubscribe(Message.METHOD_RESULT, onChannelMessage)
            if (response?.isException) {
              return reject(response.result);
            }

            return resolve(response.result);
          }
        }
        this.channel.subscribe(Message.METHOD_RESULT, onChannelMessage)
      })
    }
    Sdk.log('SDK is not initialized!')
    return Promise.resolve(null)
  }

  public navigate(event: NamedNavigationPayload | PathNavigationPayload) {
    this.channel.postMessage(Message.NAVIGATION_PUSH, event)
  }

}

export interface BackofficeInstances extends Backoffice.Api {
  route: Route,
  catalog: BackofficeCatalog,
  user: BackofficeUser,
  tenant: BackofficeTenant,
  layout: BackofficeLayout,
  authorization: BackofficeAuthorization,
  customer: BackofficeCustomer,
  plugin: BackofficePlugin,
  context: BackofficeAvailableContext
  orderpath: BackofficeOrderpath,
}

export interface ShopInstances extends Shop.Api {
  authorization: ShopAuthorization,
  route: Route,
  cart: ShopCart,
  catalog: ShopCatalog,
  account: ShopAccount,
  utils: ShopUtils
  config: ShopConfig,
  wishlist: ShopWishlist,
  context: ShopAvailableContext
  tenant: ShopTenant,
  plugin: ShopPlugin,
}

export class BackofficeSdk extends Sdk<BackofficeInstances, BackofficeAvailableContext> {
  instances = {
    route: {
      hash: '',
      query: {},
    },
    catalog: new BackofficeCatalog(this.callFunction.bind(this)),
    user: new BackofficeUser(this.callFunction.bind(this)),
    tenant: new BackofficeTenant(this.callFunction.bind(this)),
    layout: new BackofficeLayout(this.callFunction.bind(this)),
    authorization: new BackofficeAuthorization(this.callFunction.bind(this)),
    customer: new BackofficeCustomer(this.callFunction.bind(this)),
    plugin: new BackofficePlugin(this.callFunction.bind(this)),
    orderpath: new BackofficeOrderpath(this.callFunction.bind(this)),
    context: this.initializeContextClass()
  }
}

export class ShopSdk extends Sdk<ShopInstances, ShopAvailableContext> {
  instances = {
    route: {
      hash: '',
      query: {},
    },
    authorization: new ShopAuthorization(this.callFunction.bind(this)),
    cart: new ShopCart(this.callFunction.bind(this)),
    catalog: new ShopCatalog(this.callFunction.bind(this)),
    account: new ShopAccount(this.callFunction.bind(this)),
    utils: new ShopUtils(this.callFunction.bind(this)),
    config: new ShopConfig(this.callFunction.bind(this)),
    tenant: new ShopTenant(this.callFunction.bind(this)),
    wishlist: new ShopWishlist(this.callFunction.bind(this)),
    context: this.initializeContextClass(),
    plugin: new ShopPlugin(this.callFunction.bind(this)),
  }
}

export {
  BackofficeAddToCartContext,
  BackofficeEditProductLinesOnCartContext,
  ShopAddToCartContext,
  ShopEditProductLinesOnCartContext,
}
